import React from 'react';
import { WidthLimiter } from '../UI/WidthLimiter';
import { TitleBlock } from '../UI/TitleBlock/TitleBlock';
import { Text } from '../UI/Text/Text';
import { ReactComponent as Icon } from '../../assets/icons/iconCheck.svg';
import cls from './Guarantees.module.scss';
import { useTranslation } from 'react-i18next';

export const Guarantees = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.guarantees}>
        <div className={cls.title}>
          <TitleBlock>{t('customsPage.guarantees.title')}</TitleBlock>
          <Text>{t('customsPage.guarantees.text')}</Text>
        </div>
        <ul className={cls.list}>
          <li className={cls.listItem}>
            <Icon />
            <span>{t('customsPage.guarantees.listText1')}</span>
          </li>
          <li className={cls.listItem}>
            <Icon />
            <span>{t('customsPage.guarantees.listText2')}</span>
          </li>
          <li className={cls.listItem}>
            <Icon />
            <span>{t('customsPage.guarantees.listText3')}</span>
          </li>
        </ul>
      </div>
    </WidthLimiter>
  );
};
