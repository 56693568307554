import { Paths } from '../providers/router/Paths';

export const navLinks = [
  {
    to: Paths.IMPORT,
    label: 'nav.import'
  },
  {
    to: Paths.CUSTOMS,
    label: 'nav.customs'
  },
  {
    to: Paths.SHIPPING,
    label: 'nav.shipping'
  },
  {
    to: Paths.SUPPORT,
    label: 'nav.support'
  },
  {
    to: Paths.CONTACTS,
    label: 'nav.contacts'
  }
];
