import { ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import cls from './CustomNavLink.module.scss';

interface Props {
  to: string;
  onClick?: () => void;
  children: ReactNode;
}

export const CustomNavLink = ({ to, onClick, children }: Props) => {
  const setActive: NavLinkProps['className'] = ({ isActive }) =>
    isActive ? `${cls.link} ${cls.active}` : cls.link;

  return (
    <NavLink to={to} className={setActive} onClick={onClick}>
      {children}
    </NavLink>
  );
};
