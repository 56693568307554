import React from 'react';
import cls from './OurBenefits.module.scss';
import { TitleBlock } from '../UI/TitleBlock/TitleBlock';
import icon1 from '../../assets/icons/benefitsIcon1.png';
import icon2 from '../../assets/icons/benefitsIcon2.png';
import icon3 from '../../assets/icons/benefitsIcon3.png';
import icon4 from '../../assets/icons/benefitsIcon4.png';
import icon5 from '../../assets/icons/benefitsIcon5.png';
import { useTranslation } from 'react-i18next';

export const OurBenefits = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <TitleBlock>{t('shippingPage.benefits.title')}</TitleBlock>
      <ul className={cls.list}>
        <li>
          <img src={icon1} alt="icon" />
          <p>{t('shippingPage.benefits.l1')}</p>
        </li>
        <li>
          <img src={icon2} alt="icon" />
          <p>{t('shippingPage.benefits.l2')}</p>
        </li>
        <li>
          <img src={icon3} alt="icon" />
          <p>{t('shippingPage.benefits.l3')}</p>
        </li>
        <li>
          <img src={icon4} alt="icon" />
          <p>{t('shippingPage.benefits.l4')}</p>
        </li>
        <li>
          <img src={icon5} alt="icon" />
          <p>{t('shippingPage.benefits.l5')}</p>
        </li>
      </ul>
    </div>
  );
};
