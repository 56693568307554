import React from 'react';
import cls from './OurMission.module.scss';
import image from '../../assets/images/picMission.png';
import { TitleBlock } from '../UI/TitleBlock/TitleBlock';
import { Text } from '../UI/Text/Text';
import { useTranslation } from 'react-i18next';

export const OurMission = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <div className={cls.left}>
        <img src={image} alt="pic import" />
      </div>
      <div className={cls.right}>
        <TitleBlock>{t('MainPage.mission.title')}</TitleBlock>
        <Text>
          <div className={cls.textp}>{t('MainPage.mission.text1')}</div>
          <div className={cls.textp}>{t('MainPage.mission.text2')}</div>
          <div className={cls.textp}>
            <b>{t('MainPage.mission.text3b')}</b> {t('MainPage.mission.text3')}
          </div>
          <div className={cls.textp}></div>
          <div className={cls.textp}>{t('MainPage.mission.text4')}</div>
        </Text>
      </div>
    </div>
  );
};
