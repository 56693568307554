import { createBrowserRouter } from 'react-router-dom';
import { Paths } from './Paths';
import { App } from '../../App';
import { MainPage } from '../../../pages/main/MainPage';
import { ImportPage } from '../../../pages/import/ImportPage';
import { CustomsPage } from '../../../pages/customs/CustomsPage';
import { ShippingPage } from '../../../pages/shipping/ShippingPage';
import { SupportPage } from '../../../pages/support/SupportPage';
import { ContactsPage } from '../../../pages/contacts/ContactsPage';

export const router = createBrowserRouter([
  {
    path: Paths.APP,
    element: <App />,
    children: [
      {
        index: true,
        element: <MainPage />
      },
      {
        path: Paths.IMPORT,
        element: <ImportPage />
      },
      {
        path: Paths.CUSTOMS,
        element: <CustomsPage />
      },
      {
        path: Paths.SHIPPING,
        element: <ShippingPage />
      },
      {
        path: Paths.SUPPORT,
        element: <SupportPage />
      },
      {
        path: Paths.CONTACTS,
        element: <ContactsPage />
      }
    ]
  }
]);
