import React from 'react';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import { TopDescription } from '../../components/PageTopBlock/TopDescription';
import { useTranslation } from 'react-i18next';
import cls from './ContactsPage.module.scss';
import { OrderForm } from '../../components/OrderForm/OrderForm';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

export const ContactsPage = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <div className={cls.topBlock}>
          <TopDescription
            title={t('contactsPage.title')}
            titleColor={t('contactsPage.titleColor')}
          >
            {t('contactsPage.topText')}
          </TopDescription>
          <div className={cls.right}>
            <OrderForm />
          </div>
        </div>
        <div className={cls.infoBlock}>
          <YMaps>
            <div className={cls.map}>
              <Map
                defaultState={{ center: [42.865285, 74.617499], zoom: 16 }}
                width={'100%'}
              >
                <Placemark
                  options={{ iconColor: '#46AC00' }}
                  defaultGeometry={[42.865285, 74.617499]}
                />
              </Map>
              <div className={cls.mapInfo}>
                <div>
                  <h4>{t('contactsPage.map.addressTitle')}</h4>
                  <p>{t('contactsPage.map.address')}</p>
                </div>
                <div>
                  <h4>{t('contactsPage.map.phoneTitle')}</h4>
                  <p>{t('contactsPage.map.phone')}</p>
                </div>
                <div>
                  <h4>{t('contactsPage.map.emailTitle')}</h4>
                  <p>{t('contactsPage.map.email')}</p>
                </div>
              </div>
            </div>
          </YMaps>
          <div className={cls.details}>
            <h3>{t('contactsPage.details.title')}</h3>
            <ul>
              <li>{t('contactsPage.details.li1')}</li>
              <li>{t('contactsPage.details.li2')}</li>
              <li>{t('contactsPage.details.li3')}</li>
              <li>{t('contactsPage.details.li4')}</li>
              <li>{t('contactsPage.details.li5')}</li>
              <li>{t('contactsPage.details.li6')}</li>
              <li>{t('contactsPage.details.li7')}</li>
              <li>{t('contactsPage.details.li8')}</li>
              <li>{t('contactsPage.details.li9')}</li>
              <li>{t('contactsPage.details.li10')}</li>
              <li>{t('contactsPage.details.li11')}</li>
              <li>{t('contactsPage.details.li12')}</li>
              <li>{t('contactsPage.details.li13')}</li>
              <li>{t('contactsPage.details.li14')}</li>
              <li>{t('contactsPage.details.li15')}</li>
              <li>{t('contactsPage.details.li16')}</li>
              <li>{t('contactsPage.details.li17')}</li>
              <li>{t('contactsPage.details.li18')}</li>
              <li>{t('contactsPage.details.li19')}</li>
            </ul>
          </div>
        </div>
      </div>
    </WidthLimiter>
  );
};
