import React from 'react';
import cls from './Button.module.scss';
import { Link } from 'react-router-dom';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  theme?: string;
  children?: React.ReactNode;
}

export const Button = ({ children, disabled, theme, ...props }: Props) => {
  return (
    <button
      className={`${cls.btn} ${theme === 'white' ? cls.white : cls.black}`}
      disabled={disabled}
      {...props}
    >
      <div className={cls.content}>{disabled ? 'Отправка...' : children}</div>
    </button>
  );
};

interface LinkProps {
  type?: 'button' | 'submit' | 'reset';
  to: string;
  theme?: string;
  children?: React.ReactNode;
}

export const LinkButton = ({ children, to, theme, ...props }: LinkProps) => {
  return (
    <Link
      to={to}
      className={`${cls.btn} ${theme === 'white' ? cls.white : cls.black}`}
      {...props}
    >
      <div className={cls.content}>{children}</div>
    </Link>
  );
};
