import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleBlock } from '../UI/TitleBlock/TitleBlock';
import { Text } from '../UI/Text/Text';
import { ReactComponent as Icon } from '../../assets/icons/iconCheck.svg';
import cls from './Contract.module.scss';

export const Contract = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.guarantees}>
      <div className={cls.title}>
        <TitleBlock>{t('supportPage.contract.title')}</TitleBlock>
        <Text>{t('supportPage.contract.text')}</Text>
      </div>
      <ul className={cls.list}>
        <li className={cls.listItem}>
          <Icon />
          <span>{t('supportPage.contract.listText1')}</span>
        </li>
        <li className={cls.listItem}>
          <Icon />
          <span>{t('supportPage.contract.listText2')}</span>
        </li>
        <li className={cls.listItem}>
          <Icon />
          <span>{t('supportPage.contract.listText3')}</span>
        </li>
        <li className={cls.listItem}>
          <Icon />
          <span>{t('supportPage.contract.listText4')}</span>
        </li>
        <li className={cls.listItem}>
          <Icon />
          <span>{t('supportPage.contract.listText5')}</span>
        </li>
        <li className={cls.listItem}>
          <Icon />
          <span>{t('supportPage.contract.listText6')}</span>
        </li>
      </ul>
    </div>
  );
};
