import React, { ReactNode } from 'react';
import cls from './TitleBlock.module.scss';

interface Props {
  children: ReactNode;
}

export const TitleBlock = ({ children }: Props) => {
  return (
    <div className={cls.titleBlock}>
      <h3>{children}</h3>
      <div></div>
    </div>
  );
};
