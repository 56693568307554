import { useState } from 'react';
import { WidthLimiter } from '../UI/WidthLimiter';
import { NavLink } from 'react-router-dom';
import { Paths } from '../../app/providers/router/Paths';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { navLinks } from '../../app/configs/navLinks';
import { CustomNavLink } from '../CustomNavLink/CustomNavLink';
import { ReactComponent as LogoILR } from '../../assets/logoILR.svg';
import { ReactComponent as IconBurger } from '../../assets/burger-icon.svg';
import { useTranslation } from 'react-i18next';
import cls from './Header.module.scss';
import { LangSelector } from '../LangSelector/LangSelector';

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <header className={cls.header}>
      <WidthLimiter>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          size="80vw"
          className={cls.drawer}
        >
          <div className={cls.drawerContent}>
            <nav className={cls.navDrawer}>
              {navLinks.map((item) => (
                <CustomNavLink
                  key={item.to}
                  to={item.to}
                  onClick={toggleDrawer}
                >
                  {t(`${item.label}`)}
                </CustomNavLink>
              ))}
            </nav>
          </div>
        </Drawer>
        <div className={cls.container}>
          <NavLink to={Paths.APP} style={{ display: 'flex' }}>
            <LogoILR />
          </NavLink>
          <div className={cls.toolbar}>
            <div className={cls.burgerContainer}>
              <button onClick={toggleDrawer}>
                <IconBurger />
              </button>
            </div>
            <nav className={cls.nav}>
              {navLinks.map((item) => (
                <CustomNavLink key={item.to} to={item.to}>
                  {t(`${item.label}`)}
                </CustomNavLink>
              ))}
            </nav>
            <LangSelector />
          </div>
        </div>
      </WidthLimiter>
    </header>
  );
};
