import React from 'react';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import { PageTopBlock } from '../../components/PageTopBlock/PageTopBlock';
import picture from '../../assets/images/picShipping.png';
import cls from './ShippingPage.module.scss';
import { OurBenefits } from '../../components/OurBenefits/OurBenefits';
import { ShippingBlock } from '../../components/ShippingBlock/ShippingBlock';
import { useTranslation } from 'react-i18next';

export const ShippingPage = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <PageTopBlock
          image={picture}
          title={t('shippingPage.title')}
          titleColor={t('shippingPage.titleColor')}
          topText={t('shippingPage.topText')}
          topText2={t('shippingPage.topText2')}
        />
        <OurBenefits />
        <ShippingBlock />
      </div>
    </WidthLimiter>
  );
};
