import { WidthLimiter } from '../UI/WidthLimiter';
import { Paths } from '../../app/providers/router/Paths';
import { NavLink } from 'react-router-dom';
import { CustomNavLink } from '../CustomNavLink/CustomNavLink';
import { ReactComponent as LogoILR } from '../../assets/logoILR.svg';
import { navLinks } from '../../app/configs/navLinks';
import cls from './Footer.module.scss';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={cls.footer}>
      <WidthLimiter>
        <div className={cls.container}>
          <div className={cls.head}>
            <NavLink to={Paths.APP} style={{ display: 'flex' }}>
              <LogoILR />
            </NavLink>
            <nav className={cls.nav}>
              {navLinks.map((item) => (
                <CustomNavLink key={item.to} to={item.to}>
                  {t(`${item.label}`)}
                </CustomNavLink>
              ))}
            </nav>
          </div>
          <div className={cls.bottom}>{t('footer.bottomText')}</div>
        </div>
      </WidthLimiter>
    </footer>
  );
};
