import React from 'react';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import { PageTopBlock } from '../../components/PageTopBlock/PageTopBlock';
import picture from '../../assets/images/picSupport.png';
import cls from './SupportPage.module.scss';
import { useTranslation } from 'react-i18next';
import { ServicesBlock } from '../../components/SupportPage/ServicesBlock';
import { Contract } from '../../components/SupportPage/Contract';
import { TitleBlock } from '../../components/UI/TitleBlock/TitleBlock';
import { Text } from '../../components/UI/Text/Text';
import { OrderForm } from '../../components/OrderForm/OrderForm';

export const SupportPage = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <PageTopBlock
          image={picture}
          title={t('supportPage.title')}
          titleColor={t('supportPage.titleColor')}
          topText={t('supportPage.topText')}
        />
        <ServicesBlock />
        <Contract />
        <div className={cls.order}>
          <div className={cls.left}>
            <TitleBlock>{t('supportPage.order.title')}</TitleBlock>
            <Text>{t('supportPage.order.text')}</Text>
          </div>
          <div className={cls.right}>
            <OrderForm />
          </div>
        </div>
      </div>
    </WidthLimiter>
  );
};
