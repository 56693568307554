import icon1 from '../../assets/images/pics/Group15.png';
import icon2 from '../../assets/images/pics/Group17.png';
import icon3 from '../../assets/images/pics/Group7.png';
import icon4 from '../../assets/images/pics/Group6.png';
import icon5 from '../../assets/images/pics/Group20.png';
import icon6 from '../../assets/images/pics/Group19.png';
import icon7 from '../../assets/images/pics/Group16.png';
import icon8 from '../../assets/images/pics/Group18.png';
import icon9 from '../../assets/images/pics/Group21.png';
import icon10 from '../../assets/images/pics/Group8.png';
import icon11 from '../../assets/images/pics/Group5.png';
import icon12 from '../../assets/images/pics/Group9.png';

export const customsArray = [
  {
    text: 'customsPage.cards.Таможенное оформление импорта',
    image: icon11
  },
  {
    text: 'customsPage.cards.Таможенное оформление экспорта',
    image: icon12
  },
  {
    text: 'customsPage.cards.Таможенное оформление грузов из Европы',
    image: icon3
  },
  {
    text: 'customsPage.cards.Таможенное оформление грузов из Китая',
    image: icon4
  },
  {
    text: 'customsPage.cards.Таможенное оформление сборных грузов',
    image: icon10
  }
];

export const shippingArray = [
  {
    text: 'shippingPage.blockList.Авиаперевозки',
    image: icon1
  },
  {
    text: 'shippingPage.blockList.Автомобильные перевозки',
    image: icon2
  },
  {
    text: 'shippingPage.blockList.Доставка грузов из Европы',
    image: icon3
  },
  {
    text: 'shippingPage.blockList.Доставка грузов из Китая',
    image: icon4
  },
  {
    text: 'shippingPage.blockList.Железнодорожные перевозки',
    image: icon5
  },
  {
    text: 'shippingPage.blockList.Консолидация и хранение грузов',
    image: icon6
  },
  {
    text: 'shippingPage.blockList.Морские перевозки',
    image: icon7
  },
  {
    text: 'shippingPage.blockList.Негабаритные и тяжеловесные перевозки',
    image: icon8
  },
  {
    text: 'shippingPage.blockList.Опасные грузы',
    image: icon9
  },
  {
    text: 'shippingPage.blockList.Сборные грузы',
    image: icon10
  }
];
