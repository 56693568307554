import React from 'react';
import cls from './ImportPage.module.scss';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import picImport from '../../assets/images/picImport.png';
import { TitleBlock } from '../../components/UI/TitleBlock/TitleBlock';
import { categoriesImport } from '../../app/configs/categoriesImport';
import { PageTopBlock } from '../../components/PageTopBlock/PageTopBlock';
import { useTranslation } from 'react-i18next';

export const ImportPage = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <PageTopBlock
          image={picImport}
          title={t('importPage.title')}
          titleColor={t('importPage.titleColor')}
          topText={t('importPage.topText')}
          topText2={t('importPage.topText2')}
        />
        <div className={cls.categoriesList}>
          <TitleBlock>{t('importPage.categoriesTitle')}</TitleBlock>
          <ul className={cls.list}>
            {categoriesImport.map((item) => (
              <li key={item}>{t(`${item}`)}</li>
            ))}
          </ul>
        </div>
      </div>
    </WidthLimiter>
  );
};
