import React from 'react';
import cls from './CustomsProcess.module.scss';
import { WidthLimiter } from '../UI/WidthLimiter';
import { TitleBlock } from '../UI/TitleBlock/TitleBlock';
import { useTranslation } from 'react-i18next';

export const CustomsProcess = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <WidthLimiter>
        <div className={cls.content}>
          <TitleBlock>{t('customsPage.process.title')}</TitleBlock>
          <div className={cls.list}>
            <div className={cls.listItem}>
              <div className={cls.leftLine}></div>
              <div className={cls.circle}>1</div>
              <h3>{t('customsPage.process.Консультация')}</h3>
              <p>{t('customsPage.process.КонсультацияText')}</p>
            </div>
            <div className={cls.listItem}>
              <div className={cls.leftLine}></div>
              <div className={cls.circle}>2</div>
              <h3>{t('customsPage.process.Изучение')}</h3>
              <p>{t('customsPage.process.ИзучениеText')}</p>
            </div>
            <div className={cls.listItem}>
              <div className={cls.leftLine}></div>
              <div className={cls.circle}>3</div>
              <h3>{t('customsPage.process.Подготовка')}</h3>
              <p>{t('customsPage.process.ПодготовкаText')}</p>
            </div>
            <div className={cls.listItem}>
              <div className={cls.leftLine}></div>
              <div className={cls.circle}>4</div>
              <h3>{t('customsPage.process.Отгрузка')}</h3>
              <p>
                {t('customsPage.process.ОтгрузкаText')}
                <ul className={cls.subList}>
                  <li>{t('customsPage.process.ОтгрузкаLi1')}</li>
                  <li>{t('customsPage.process.ОтгрузкаLi2')}</li>
                </ul>
              </p>
            </div>
            <div className={cls.listItem}>
              <div className={cls.leftLine}></div>
              <div className={cls.circle}>5</div>
              <h3>{t('customsPage.process.Оформление')}</h3>
              <p>{t('customsPage.process.ОформлениеText')}</p>
            </div>
          </div>
        </div>
      </WidthLimiter>
    </div>
  );
};
