import React from 'react';
import cls from './TitleColor.module.scss';

interface Props {
  title: string;
  titleColor: string;
}

export const TitleColor = ({ title, titleColor }: Props) => {
  return (
    <h2 className={cls.title}>
      {title} <span>{titleColor}</span>
    </h2>
  );
};
