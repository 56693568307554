import { LoaderLayout } from '../components/UI/LoaderLayout';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

export function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <LoaderLayout>
          <Outlet />
        </LoaderLayout>
      </main>
      <Footer />
    </div>
  );
}
