import React from 'react';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import { TitleColor } from '../../components/UI/TitleColor/TitleColor';
import pic from '../../assets/images/picMain.png';
import cls from './MainPage.module.scss';
import { Text } from '../../components/UI/Text/Text';
import { LinkButton } from '../../components/UI/Button/Button';
import { ServicesMain } from '../../components/MainPage/ServicesMain';
import { OurMission } from '../../components/MainPage/OurMission';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../app/providers/router/Paths';

export const MainPage = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <div className={cls.topBlock}>
          <div className={cls.left}>
            <TitleColor
              title={t('MainPage.title')}
              titleColor={t('MainPage.titleColor')}
            />
            <div className={cls.description}>
              <div className={cls.text}>
                <div>{t('MainPage.text1')}</div>
                <Text>{t('MainPage.text2')}</Text>
              </div>
              <LinkButton to={Paths.CONTACTS}>
                {t('FormOrder.button')}
              </LinkButton>
            </div>
          </div>
          <div className={cls.right}>
            <img src={pic} alt="main image" />
          </div>
        </div>
        <ServicesMain />
        <OurMission />
      </div>
    </WidthLimiter>
  );
};
