export const categoriesImport = [
  'importPage.categories.фрукты и овощи',
  'importPage.categories.табачные изделия',
  'importPage.categories.напитки',
  'importPage.categories.сахар',
  'importPage.categories.шоколад и кондитерские изделия',
  'importPage.categories.кофе',
  'importPage.categories.чай',
  'importPage.categories.какао',
  'importPage.categories.пряности',
  'importPage.categories.текстильные товары',
  'importPage.categories.одежда',
  'importPage.categories.обувь',
  'importPage.categories.непродовольственные товары потребительского назначения',
  'importPage.categories.бытовая радио и телеаппаратура',
  'importPage.categories.ковры',
  'importPage.categories.бытовые электротовары',
  'importPage.categories.бытовая мебель',
  'importPage.categories.осветительные приборы',
  'importPage.categories.компьютеры (ЭВМ)',
  'importPage.categories.программное обеспечение',
  'importPage.categories.компоненты электронного оборудования',
  'importPage.categories.офисная мебель',
  'importPage.categories.компоненты телекоммуникационное оборудование',
  'importPage.categories.машины и оборудование для горнодобывающей промышленности',
  'importPage.categories.машины и оборудование для строительства',
  'importPage.categories.офисные машины',
  'importPage.categories.переферийные устройства',
  'importPage.categories.прочее'
];
