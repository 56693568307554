import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './app/providers/router/AppRouter';
import { LoaderLayout } from './components/UI/LoaderLayout';
import './index.scss';
import './i18n';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <LoaderLayout>
        <RouterProvider router={router} />
      </LoaderLayout>
    </React.StrictMode>
  );
}
