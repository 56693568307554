import React from 'react';
import cls from './ListItemGrey.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
  image: string;
}

export const ListItemGrey = ({ text, image }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={cls.item}>
      <img src={image} alt="icon" />
      <h5>{t(`${text}`)}</h5>
    </div>
  );
};
