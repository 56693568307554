import React from 'react';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import { PageTopBlock } from '../../components/PageTopBlock/PageTopBlock';
import { customsArray } from '../../app/configs/listItems';
import { ListItemGrey } from '../../components/UI/ListItemGrey/ListItemGrey';
import { CustomsProcess } from '../../components/CustomsPage/CustomsProcess';
import { useTranslation } from 'react-i18next';
import picture from '../../assets/images/picCustoms.png';
import cls from './CustomsPage.module.scss';
import { Guarantees } from '../../components/CustomsPage/Guarantees';

export const CustomsPage = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <WidthLimiter>
        <PageTopBlock
          image={picture}
          title={t('customsPage.title')}
          titleColor={t('customsPage.titleColor')}
          topText={t('customsPage.topText')}
          topText2={t('customsPage.topText2')}
        />
      </WidthLimiter>
      <CustomsProcess />
      <Guarantees />
      <WidthLimiter>
        <ul className={cls.list}>
          {customsArray.map((item) => (
            <li key={item.text}>
              <ListItemGrey text={item.text} image={item.image} />
            </li>
          ))}
        </ul>
      </WidthLimiter>
    </div>
  );
};
