import React, { useState } from 'react';
import cls from './OrderForm.module.scss';
import { Input } from '../UI/Input/Input';
import { Button } from '../UI/Button/Button';
import { useTranslation } from 'react-i18next';

const sendForm = (data: { name: string; phone: string }) => {
  return fetch('https://api.internationalroutes.kg/api/mails', {
    method: 'POST',
    body: JSON.stringify(data)
  });
};

export const OrderForm = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [snackbar, setSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleSnackbar = () => {
    setSnackbar(true);

    setTimeout(function () {
      setSnackbar(false);
    }, 2950);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await sendForm({ name, phone });
      if (response.ok) {
        setName('');
        setPhone('');
        toggleSnackbar();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={cls.form} onSubmit={handleSubmit}>
      <Input
        required
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
        placeholder={t('FormOrder.FIO')}
      />
      <Input
        required
        value={phone}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPhone(e.target.value)
        }
        placeholder={t('FormOrder.Phone')}
      />
      <Button theme="white" disabled={loading} type="submit">
        {t('FormOrder.button')}
      </Button>
      <div className={cls.info}>
        {t('FormOrder.policy')} {t('FormOrder.policyLink')}
        <a href="#"></a>
      </div>
      <div className={snackbar ? `${cls.snackbar} ${cls.show}` : cls.snackbar}>
        Заявка успешно отправлена!
      </div>
    </form>
  );
};
