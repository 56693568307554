import React from 'react';
import cls from './ServicesMain.module.scss';
import { TitleBlock } from '../UI/TitleBlock/TitleBlock';
import { ReactComponent as Icon1 } from '../../assets/icons/mainList/Icon1.svg';
import { ReactComponent as Icon2 } from '../../assets/icons/mainList/Icon2.svg';
import { ReactComponent as Icon3 } from '../../assets/icons/mainList/Icon3.svg';
import { ReactComponent as Icon4 } from '../../assets/icons/mainList/Icon4.svg';
import { ReactComponent as Icon5 } from '../../assets/icons/mainList/Icon5.svg';
import { ReactComponent as Icon6 } from '../../assets/icons/mainList/Icon6.svg';
import { ReactComponent as Icon7 } from '../../assets/icons/mainList/Icon7.svg';
import { ReactComponent as Icon8 } from '../../assets/icons/mainList/Icon8.svg';
import { ReactComponent as Icon9 } from '../../assets/icons/mainList/Icon9.svg';
import { useTranslation } from 'react-i18next';

export const ServicesMain = () => {
  const { t } = useTranslation();

  return (
    <ul className={cls.container}>
      <li className={cls.title}>
        <TitleBlock>{t('MainPage.services.title')}</TitleBlock>
      </li>
      <li className={cls.item}>
        <Icon1 />
        <span>{t('MainPage.services.li1')}</span>
      </li>
      <li className={cls.item}>
        <Icon2 />
        <span>{t('MainPage.services.li2')}</span>
      </li>
      <li className={cls.item}>
        <Icon3 />
        <span>{t('MainPage.services.li3')}</span>
      </li>
      <li className={cls.item}>
        <Icon4 />
        <span>{t('MainPage.services.li4')}</span>
      </li>
      <li className={cls.item}>
        <Icon5 />
        <span>{t('MainPage.services.li5')}</span>
      </li>
      <li className={cls.item}>
        <Icon6 />
        <span>{t('MainPage.services.li6')}</span>
      </li>
      <li className={cls.item}>
        <Icon7 />
        <span>{t('MainPage.services.li7')}</span>
      </li>
      <li className={cls.item}>
        <Icon8 />
        <span>{t('MainPage.services.li8')}</span>
      </li>
      <li className={cls.item}>
        <Icon9 />
        <span>{t('MainPage.services.li9')}</span>
      </li>
    </ul>
  );
};
