import React, { ReactNode } from 'react';
import cls from './PageTopBlock.module.scss';
import { Text } from '../UI/Text/Text';
import { TitleColor } from '../UI/TitleColor/TitleColor';

interface Props {
  title: string;
  titleColor: string;
  children: ReactNode;
}

export const TopDescription = ({ title, titleColor, children }: Props) => {
  return (
    <div className={cls.left}>
      <TitleColor title={title} titleColor={titleColor} />
      <Text>{children}</Text>
    </div>
  );
};
