import React from 'react';
import cls from './ServicesBlock.module.scss';
import { TitleBlock } from '../UI/TitleBlock/TitleBlock';
import { ReactComponent as Icon } from '../../assets/icons/iconCheck2.svg';
import { useTranslation } from 'react-i18next';

export const ServicesBlock = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.services}>
      <TitleBlock>{t('supportPage.services.title')}</TitleBlock>
      <div className={cls.servicesCards}>
        <div className={cls.card}>
          <h3>{t('supportPage.services.card1.title')}</h3>
          <p>{t('supportPage.services.card1.text')}</p>
          <ul className={cls.list}>
            <li className={cls.item}>
              <Icon />
              <p>{t('supportPage.services.card1.li1')}</p>
            </li>
            <li className={cls.item}>
              <Icon />
              <p>{t('supportPage.services.card1.li2')}</p>
            </li>
          </ul>
        </div>
        <div className={cls.card}>
          <h3>{t('supportPage.services.card2.title')}</h3>
          <p>{t('supportPage.services.card2.text')}</p>
          <ul className={cls.list}>
            <li className={cls.item}>
              <Icon />
              <p>{t('supportPage.services.card2.li1')}</p>
            </li>
            <li className={cls.item}>
              <Icon />
              <p>{t('supportPage.services.card2.li2')}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
