import React from 'react';
import { shippingArray } from '../../app/configs/listItems';
import { TitleBlock } from '../UI/TitleBlock/TitleBlock';
import { ListItemGrey } from '../UI/ListItemGrey/ListItemGrey';
import cls from './ShippingBlock.module.scss';
import { Text } from '../UI/Text/Text';
import { useTranslation } from 'react-i18next';

export const ShippingBlock = () => {
  const { t } = useTranslation();

  return (
    <ul className={cls.container}>
      <li className={cls.title}>
        <TitleBlock>{t('shippingPage.blockList.title')}</TitleBlock>
        <Text>{t('shippingPage.blockList.text')}</Text>
      </li>
      {shippingArray.map((item) => (
        <li key={item.text}>
          <ListItemGrey text={item.text} image={item.image} />
        </li>
      ))}
    </ul>
  );
};
