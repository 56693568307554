import React from 'react';
import { TopDescription } from './TopDescription';
import cls from './PageTopBlock.module.scss';

interface Props {
  image: string;
  title: string;
  titleColor: string;
  topText: string;
  topText2?: string;
}

export const PageTopBlock = ({
  image,
  title,
  titleColor,
  topText,
  topText2
}: Props) => {
  return (
    <div className={cls.topBlock}>
      <TopDescription title={title} titleColor={titleColor}>
        {topText}
        {topText2 && (
          <>
            <br />
            <br />
            {topText2}
          </>
        )}
      </TopDescription>
      <div className={cls.right}>
        <img src={image} alt="pic import" />
      </div>
    </div>
  );
};
